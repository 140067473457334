import React, { Component } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Container } from "react-bootstrap";
import bookImage from "../assets/daringtobedifferent.jpg";
import AlbumsData from "./AlbumsData.js";

class AlbumGallery extends Component {

    constructor(props) {
        super();

        const match = props.match;
        if (
            match === undefined ||
            match.params === undefined ||
            match.params.name === undefined
        ) {
            this.state = { error: "Please send in a valid name" };
            return;
        }
        this.state = {
            name: match.params.name,
            photos: null,
            currentImage: 0,
            viewerIsOpen: false
        };
    }
    async componentDidMount() {
        if (this.state.name === undefined) {
            this.setState({ error: "Please send in a valid album name" });
        }
        try {
            const photos = await AlbumsData.getImagesByAlbum(this.state.name); // Wait for the data
            this.setState({ photos }); // Update the state with the fetched photos
        } catch (error) {
            console.error("Error fetching images:", error);
            this.setState({ error: "Failed to fetch album photos" });
        }

    }

    openLightbox = (event, { photo, index }) => {
        this.setState({ currentImage: index, viewerIsOpen: true });
    };

    closeLightbox = () => {
        this.setState({ currentImage: 0, viewerIsOpen: false });
    };
    render() {
        if (this.state.photos === null || this.state.photos === undefined) {
            return <>Loading....</>
        }

        return (
            <Container>
                <div>
                    <div className="bookpromo">
                        <figure>
                            <a href="https://www.amazon.com/dp/B0C52QPWM3">
                                <img src={bookImage} alt="alternative source"
                                    style={{ maxWidth: '50%', maxHeight: '50%' }} />
                            </a>
                        </figure>
                        <h5>Get a copy of my latest book <a href="https://www.amazon.com/dp/B0C52QPWM3">on Amazon.com</a>.
                            If you are in India, you can order a paperback <a href="https://store.pothi.com/book/raji-rajagopalan-daring-be-different-stories-and-tips-woman-leader-tech/">on Pothi.com</a>
                        </h5>
                    </div>
                </div>
                <br />
                <div>
                    <a href="/photos"><p>Return to All Photos</p></a>
                    <Gallery photos={this.state.photos} onClick={this.openLightbox} />
                    {<ModalGateway>
                        {this.state.viewerIsOpen ? (
                            <Modal onClose={this.closeLightbox} >
                                <Carousel
                                    currentIndex={this.state.currentImage}
                                    views={this.state.photos.map(x => ({
                                        ...x,
                                        srcSet: this.state.photos,
                                        caption: ""
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>}
                </div>
            </Container>);
    }


}

export default AlbumGallery;