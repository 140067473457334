import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import { Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import WebsiteBanner from "../components/WebsiteBanner";
import AlbumsData from "./AlbumsData.js";


class PhotoPortfolio extends Component {
    constructor(props) {
        super();

        this.state = {
            albumsData: new AlbumsData(),
            albums: [],
            url: `${process.env.REACT_IMAGE_JSON_URL}`,
            loading: true,
            error: null
        };
    }
    componentDidMount() {
        AlbumsData.getAlbumsJsonData()
            .then((jsonData) => {
                this.setState({
                    albums: AlbumsData.albumData,
                    loading: false,
                });
            })
    }

    render() {
        const { error, loading } = this.state;

        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error: {error}</div>;


        return (
            <div >
                <WebsiteBanner
                    bannerStyle="blogListBanner"
                    title="My Photography Portfolio"
                ></WebsiteBanner>
                <Container>
                    <Row>
                        {Object.entries(AlbumsData.albumData).map(([item, photos]) => {
                            return (<div key={item} className="album">
                                <Card
                                    className="mb-3 mr-1 ml-1"
                                    bg="dark"
                                    text="white"
                                    style={{ width: "320px" }}
                                >
                                    <a href={`/gallery/${item}`} className="nounderline">

                                        <Card.Img src={photos[0]}
                                            className="center-cropped" />
                                        <Card.ImgOverlay
                                            style={{ height: "6em" }}>
                                            <Card.Text className="blog-title">
                                                {item}
                                            </Card.Text>
                                        </Card.ImgOverlay>
                                    </a>
                                </Card>
                            </div>)
                        })}

                    </Row>
                </Container>
            </div>

        );
    }
}
export default PhotoPortfolio;