class AlbumsData {
  static initialized = false;
  static albumData = {};
  static url = "https://thisisrajiraj.github.io/assets/images/portfoliopics/images.json";

  static getUniqueAlbums(data) {
    data.forEach((item) => {

      // Check if the album key exists
      if (!this.albumData[item.album]) {
        // Initialize with an array containing the first item
        this.albumData[item.album] = [item.src];
      } else {
        // Append to the existing album's array
        this.albumData[item.album].push(item.src);
      }
      this.initialized = true;
    })
  }

  // Fetch albums JSON data
  static async getAlbumsJsonData() {
    try {
      const response = await fetch(this.url);
      const jsonData = await response.json();
      this.getUniqueAlbums(jsonData);
    } catch (error) {
      console.error('Error fetching albums JSON data:', error);
    }

  }

  // Filter images by album
  static async getImagesByAlbum(name) {
    if (!this.initialized)
      await this.getAlbumsJsonData();
    let toRet = [];
    this.albumData[name].forEach((item) => {
      toRet.push(
        {
          "src": item,
          "width": 1,
          "height": 1
        });
    })
    return toRet;
  }
}

export default AlbumsData;
