import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import HomeImg from "../assets/homeicon.svg";
import NavDropdown from 'react-bootstrap/NavDropdown';

export const NavigationBar = () => (
  <Navbar variant="dark" bg="dark" expand="lg" fixed="top">
    <Navbar.Brand href="/">
      <img
        src={HomeImg}
        width="30"
        height="30"
        className="d-inline-block align-top"
        alt="RajiRaj"
      /> </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav>
        <Nav.Link href="/about">About</Nav.Link>
        <Nav.Link href="/blog">Blog</Nav.Link>
        <Nav.Link href="/subscribe">Newsletter</Nav.Link>
        <Nav.Link href="/photos">Photography</Nav.Link>
        <Nav.Link href="/speaking">Speaking</Nav.Link>
        <Nav.Link href="https://daringgpt.azurewebsites.net/">MentorGPT</Nav.Link>        
      </Nav>
      <Nav>
        <div style={{ paddingLeft: "5px" }}></div>

        <NavDropdown title="My Books" id="basic-nav-dropdown">
          <NavDropdown.Item href="/daring">Daring to be Different: The Book</NavDropdown.Item>
          <NavDropdown.Item href="/daringworkbook">
          Daring to be Different: The Workbook
          </NavDropdown.Item>
        </NavDropdown>
        <div style={{ paddingLeft: "5px" }}></div>
      </Nav>
    </Navbar.Collapse>

  </Navbar>

);

export default NavigationBar;
